import { Link } from "gatsby"
import React from "react"
import { Box } from "theme-ui";

import "./header.css";

const Header = () => {

  const options = [
    {label: `Home`, to: `#home`}, 
    {label: `About Us`, to: `#about`}, 
    {label: `Our Specializations`, to: `#specs`}, 
    {label: `Our Technologies`, to: `#techs`}
  ];

  return (<Box id="home" className="header" sx={{display: ["none", "none", "block"] }}>
    <Box sx={{paddingY: 0, paddingX: [0, 60, 130], position: "relative"}}>
      <h1 style={{ margin: 0 }}>
        {options.map((option, idx) => (
          <Link key={idx} to={option.to}>
            {option.label}
          </Link>
        ))}
      </h1>
    </Box>
  </Box>);
}

export default Header
